.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: url('../components/assets/background_ki.jpg');
    background-size: cover;
}

.login {
    display: flex;
    flex-direction: column;
    width: 600px;
    background: transparent;
    backdrop-filter: blur(30px);
    padding-bottom: 30px;
    border-radius: 40px;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 30px;
}

.text {
    color: #fff;
    font-size: 48px;
    font-weight: 700;
}

.underline {
    width: 61px;
    height: 6px;
    background: #fff;
    border-radius: 9px;
}

.inputs {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.input {
    display: flex;
    align-items: center;
    margin: auto;
    width: 480px;
    height: 50px;
    background: #eaeaea;
    border-radius: 40px;
}

.icon {
    margin: 0px 10px;
    color: #797979;
}

.input input {
    height: 50px;
    width: 400px;
    background: transparent;
    border: none;
    outline: none;
    color: #797979;
    font-size: 19px;
}

.button-container {
    text-align: center;
}

.button-container .button {
    width: 220px;
    height: 45px;
    background: #fff;
    border: none;
    outline: none;
    border-radius: 40px;
    cursor: pointer;
    font-size: 16px;
    color: #333;
    font-weight: 700;
    margin-top: 15px;
}
