.header-content {
  margin: 0px 20px 10px 20px;
  border-radius: 25px;
  background: #20262b;
  padding: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-item {
  display: flex;
  align-items: center;
}

.logo-text-container {
  overflow: hidden;
}

.logo {
  height: 65px;
  margin-right: 6px;
}
  
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
  
.logo-text {
  margin-top: 0;
  margin-bottom: 0;
  animation: slideInFromLeft 1.25s ease-out;
  backface-visibility: hidden;
  perspective: 1000px;
  color: white;
  font: small-caps bold 55px/1 sans-serif;
}
  
.logout {
  color: rgba(255, 255, 255, 0.534);
  margin-right: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}