.bg {
  background: #243f57;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.streched {
  width: 100%;
  height: 100%;
}

.main-content-container {
  margin: 10px 10px 20px 20px;
  border-radius: 25px;
  padding: 20px;
  background: #20262b;
  overflow: hidden;
}

.secondary-content-container {
  margin: 10px 20px 20px 10px;
  border-radius: 25px;
  display: flex;
  padding: 20px;
  background: #20262b;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}

.graf-container {
  display: flex;
  flex: 1;
  overflow: hidden;
  margin: 20px;
  border-radius: 25px;
}

.white-heading-small {
  color: white;
  margin: 20px 20px 0 20px;
  text-transform: uppercase;
  font: small-caps bold 30px/1 sans-serif;
}

.white-heading-big {
  color: white;
  margin: 30px 30px 10px 30px;
  text-transform: uppercase;
  font: small-caps bold 45px/1 sans-serif;
}

.text-secondary {
  color: rgba(255, 255, 255, 0.534);
}

.fade {
  transition: visibility 0s, opacity 1s linear;
  visibility: visible;
  opacity: 1;
}

.d-none {
  transition: visibility 0s 1s, opacity 1s linear;
  visibility: hidden;
  opacity: 0;
}

.slides-container {
  position: relative;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}